import React, { useState, useEffect } from "react";
import { Button, Form, Drawer, Select, Input, DatePicker } from "antd";
import dayjs from "dayjs";
import axios from "axios";

import endpoints from "../../../services/endpoints";

function Update({ data, open, close }) {
  const [isButtonLoading, setIsButtonLoading] = useState(false);

  const [form] = Form.useForm();

  useEffect(() => {
    if (Object.keys(data).length > 0) {
      data.date_time = data.date_time ? dayjs(data.date_time) : null;
      form.setFieldsValue(data);
    }
  }, [data]);

  function handleClose() {
    close();
    form.resetFields();
  }

  function handleSubmit(values) {
    setIsButtonLoading(true);

    axios
      .post(endpoints.notification.update, {
        data: { ...values, platform: "app" },
      })
      .then((res) => {
        setIsButtonLoading(false);
        handleClose();
      })
      .catch((error) => {
        setIsButtonLoading(false);
        console.error(error);
      });
  }

  return (
    <Drawer
      key={`drawer-update-notification`}
      title="Editar notificação"
      width={800}
      onClose={handleClose}
      open={open}
      bodyStyle={{
        paddingBottom: 80,
      }}
      extra={[
        <Button disabled={isButtonLoading} className="mr-10" onClick={handleClose}>
          Cancelar
        </Button>,
        <Button loading={isButtonLoading} type="primary" onClick={form.submit}>
          Editar
        </Button>,
      ]}
    >
      <Form form={form} onFinish={handleSubmit} layout="vertical">
        <Form.Item name="id" hidden>
          <Input />
        </Form.Item>
        <Form.Item
          label="Título"
          name="title"
          rules={[
            {
              required: true,
              message: "Este campo é obrigatório",
            },
          ]}
        >
          <Input placeholder="Título" size="large" />
        </Form.Item>
        <Form.Item
          label="Descrição"
          name="description"
          rules={[
            {
              required: true,
              message: "Este campo é obrigatório",
            },
          ]}
        >
          <Input placeholder="Descrição" size="large" />
        </Form.Item>
        <Form.Item label="Data/Hora" name="date_time">
          <DatePicker size="large" placeholder="Data/Hora" showTime format={"YYYY-MM-DD HH:mm"} />
        </Form.Item>
        <Form.Item label="Redirecionar" name="path">
          <Input size="large" placeholder="/calculadoras" />
        </Form.Item>
      </Form>
    </Drawer>
  );
}

export default Update;
