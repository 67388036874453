import React, { useState, useEffect } from "react";
import { Button, Form, Drawer, Select, Input, DatePicker } from "antd";
import axios from "axios";

import endpoints from "../../../services/endpoints";

import "react-quill/dist/quill.snow.css";
import "../../../assets/styles/admin.css";

function Create({ open, close }) {
  const [isButtonLoading, setIsButtonLoading] = useState(false);

  const [form] = Form.useForm();

  function handleClose() {
    close();
    form.resetFields();
  }

  function handleSubmit(values) {
    setIsButtonLoading(true);

    axios
      .post(endpoints.notification.create, {
        data: { ...values, platform: "app" },
      })
      .then((res) => {
        setIsButtonLoading(false);
        handleClose();
      })
      .catch((error) => {
        setIsButtonLoading(false);
        console.error(error);
      });
  }

  return (
    <Drawer
      className="drawer-user"
      key={`drawer-create-notification`}
      title="Criar notificação"
      width={800}
      onClose={handleClose}
      open={open}
      bodyStyle={{
        paddingBottom: 80,
      }}
      extra={
        <>
          <Button disabled={isButtonLoading} className="mr-10" onClick={handleClose}>
            Cancelar
          </Button>
          <Button loading={isButtonLoading} type="primary" onClick={form.submit}>
            Criar
          </Button>
        </>
      }
    >
      <Form form={form} onFinish={handleSubmit} layout="vertical">
        <Form.Item
          label="Título"
          name="title"
          rules={[
            {
              required: true,
              message: "Este campo é obrigatório",
            },
          ]}
        >
          <Input placeholder="Título" size="large" />
        </Form.Item>
        <Form.Item
          label="Descrição"
          name="description"
          rules={[
            {
              required: true,
              message: "Este campo é obrigatório",
            },
          ]}
        >
          <Input placeholder="Descrição" size="large" />
        </Form.Item>
        <Form.Item label="Data/Hora" name="date_time">
          <DatePicker size="large" placeholder="Data/Hora" showTime format={"YYYY-MM-DD HH:mm"} />
        </Form.Item>
        <Form.Item label="Redirecionar" name="path">
          <Input size="large" placeholder="/calculadoras" />
        </Form.Item>
      </Form>
    </Drawer>
  );
}

export default Create;
