import React, { useState, useEffect } from "react";
import { Col, Row, Button, message, Table, Input, Form } from "antd";
import { EditOutlined, DeleteOutlined, ReloadOutlined } from "@ant-design/icons";
import axios from "axios";

import Create from "../../../components/dashboard/Notification/Create";
import Update from "../../../components/dashboard/Notification/Update";
import Delete from "../../../components/dashboard/Notification/Delete";
import endpoints from "../../../services/endpoints";

import "../../../assets/styles/admin.css";
import dayjs from "dayjs";

const Notifications = () => {
  const [data, setData] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [selectedData, setSelectedData] = useState({});
  const [isOpenCreate, setIsOpenCreate] = useState(false);
  const [isOpenUpdate, setIsOpenUpdate] = useState(false);
  const [isOpenDelete, setIsOpenDelete] = useState(false);
  const [isOpenImport, setIsOpenImport] = useState(false);
  const [isOpenExportDrawer, setIsOpenExportDrawer] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isButtonSendingLoading, setIsButtonSendingLoading] = useState(true);
  const [messageApi, contextHolder] = message.useMessage();

  const [form] = Form.useForm();

  const columns = [
    {
      title: "Títuto",
      dataIndex: "title",
    },
    {
      title: "Descrição",
      dataIndex: "description",
    },

    {
      title: "Código",
      dataIndex: "code",
    },
    {
      title: "Convidado por",
      dataIndex: "invited_from",
    },
    {
      title: "",
      dataIndex: "actions",
    },
  ];

  useEffect(() => {
    handleGetData();
  }, []);

  function handleGetData() {
    setIsLoading(true);
    axios
      .get(endpoints.notification.read)
      .then((res) => {
        setData(res.data);
        handlePrepareData(res.data);
      })
      .catch((error) => {
        setIsLoading(false);
        console.error(error);
      });
  }

  function handlePrepareData(item) {
    setIsLoading(true);
    let aux = [];
    for (let i = 0; i < item.length; i++) {
      aux.push({
        id: item[i].id,
        title: item[i].title,
        description: item[i].description,
        date_time: item[i].date_time ? dayjs(item[i].date_time).format("YYYY-MM-DD HH:mm:ss") : null,
        path: item[i].path,
        full_data: item[i],
        actions: (
          <div className="d-flex jc-end ai-center">
            {!item[i].sended && (
              <Button className="mr-10" onClick={() => handleOpenUpdate(item[i])}>
                <EditOutlined />
              </Button>
            )}
            <Button danger onClick={() => handleOpenDelete(item[i])}>
              <DeleteOutlined />
            </Button>
          </div>
        ),
      });
    }

    setIsLoading(false);
    setTableData(aux);
  }

  function handleOpenCreate() {
    setIsOpenCreate(true);
  }

  function handleCloseCreate() {
    setIsOpenCreate(false);
    handleGetData();
  }

  function handleOpenUpdate(item) {
    setSelectedData(item);
    setIsOpenUpdate(true);
  }

  function handleCloseUpdate() {
    setSelectedData({});
    setIsOpenUpdate(false);
    handleGetData();
  }
  function handleOpenDelete(item) {
    setSelectedData(item);
    setIsOpenDelete(true);
  }

  function handleCloseDelete() {
    setSelectedData({});
    setIsOpenDelete(false);
    handleGetData();
  }

  return (
    <Row>
      {contextHolder}
      <Create open={isOpenCreate} close={handleCloseCreate} />
      <Update data={selectedData} open={isOpenUpdate} close={handleCloseUpdate} />
      <Delete data={selectedData} open={isOpenDelete} close={handleCloseDelete} />
      <Col span={24}>
        <Row className="title-admin-page d-flex jc-sb ai-center">
          <Col span={6}>
            <h2 className="m-0">Notificações</h2>
          </Col>
          <Col span={18}>
            <div className="d-flex jc-end ai-center">
              <Button className="mr-10" size="large" onClick={handleGetData}>
                <ReloadOutlined />
              </Button>
              <Button className="mr-10" size="large" onClick={handleOpenCreate}>
                Adicionar
              </Button>
            </div>
          </Col>
        </Row>
        <Row gutter={[24, 24]}>
          <Col span={24}>
            <Table loading={isLoading} columns={columns} dataSource={tableData} />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default Notifications;
