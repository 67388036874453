import React, { useState } from "react";
import { Button, Col, Row, Modal } from "antd";
import axios from "axios";

import endpoints from "../../../services/endpoints";

import "../../../assets/styles/admin.css";

function Delete({ open, close, data }) {
  const [isButtonLoading, setIsButtonLoading] = useState(false);

  function handleSubmit() {
    setIsButtonLoading(true);
    axios
      .post(endpoints.notification.delete, {
        data: data,
      })
      .then((res) => {
        setIsButtonLoading(false);
        close();
      })
      .catch((error) => {
        setIsButtonLoading(false);
        console.error(error);
      });
  }

  return (
    <Modal
      className="modal-delete-guide p-20"
      key={`modal-delete-guide-${data.id}`}
      width={800}
      onCancel={close}
      open={open}
      footer={
        <>
          <Button disabled={isButtonLoading} size="large" onClick={close}>
            Cancelar
          </Button>
          <Button loading={isButtonLoading} type="primary" size="large" onClick={handleSubmit}>
            Apagar
          </Button>
        </>
      }
    >
      <Row>
        <Col span={24} className="p-20">
          <p className="f-20 bold blue">Deseja mesmo apagar esta notificação:</p>
          <p className="f-16 mt-20">{data.title}</p>
        </Col>
      </Row>
    </Modal>
  );
}

export default Delete;
